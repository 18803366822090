import * as React from "react";
import {Redirect, useHistory} from "react-router-dom";
import {AppointmentDto} from "../../dto/AppointmentDto";
import {IApiAction} from "../../Interfaces";
import moment from "moment";
import {searchAppointmentDatabyid, UpdateAppointment} from "../../services/persondetailsdata";
import {errorToast, successToast} from "../../utils/toast";
import AppointmentFormComponent from "./AppointmentFormComponent";
import {CommissionResultModelDto} from "../../dto/CommissionResultModelDto";
// @ts-ignore
import FileSaver from "file-saver";
import {Button, Card, Col, Row, Container} from "react-bootstrap";
import CommissionListComponent from "../commissions/CommissionListComponent";
import {useLocation} from 'react-router-dom'
import {createContext, useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {BASE_URL} from "../../config/constant";
import {BreadcrumbType} from "../../components/AppBreadcrumb";
import {useAppDispatch} from "../../app/store";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";
import Spinner from "../../components/Loader/Spinner";
import {personsApi} from "../../app/services/personApi";

interface AppointmentUpdateState extends IApiAction<AppointmentDto> {
    commissionResult?: CommissionResultModelDto;
    attachmentData?: any;
    attachmentName?: string;
    canAttach?: boolean;
    selectedRows?: any[];
}

export interface ValidContextProps {
    valid: boolean
    setValid: (a: boolean) => void
}

export const ValidContext = createContext<ValidContextProps>({
    valid: false,
    setValid: () => null
})

const AppointmentUpdateView = () => {
    const breadcrumbs: BreadcrumbType[] = [];

    const {checkFeatureAccess, user} = React.useContext(UserContext);
    const [isBusy, setBusy] = useState<boolean>(false);
    const [isValid, setValid] = useState<boolean>(true);
    const [isDirty, setDirty] = useState<boolean>(false);

    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const commissionId = (query.get("commissionId"));

    const [state, setState] = useState<AppointmentUpdateState>({entity: {commissionId: 0}});
    const canEditCommission = checkFeatureAccess(user, 'Edit Commission');
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const data = await searchAppointmentDatabyid(commissionId);

            if (data) {
                setState({
                    entity:
                        {
                            commissionId: data.commissionId,
                            court: data.courtTribunalId.toString(),
                            state: data.stateId.toString(),
                            city: data.cityId.toString(),
                            startdate: data.startDate,
                            enddate: data.endDate,
                            position: data.arPosition?.toString(),
                            endreason: data.endReason,
                            employmenttype: data.employmentType?.toString(),
                            termtype: data.arTermTypeCode?.toString(),
                            personId: data.personId?.toString(),
                            citys: data.citys,
                            createdDate: data.createdDate,
                            createdId: data.createdId
                        }
                    , commissionResult: data
                });
                breadcrumbs.push({
                    active: false,
                    name: "Person Details",
                    pathname: "/persondetails"
                })
                breadcrumbs.push({
                    active: false,
                    name: "Edit Person",
                    pathname: "/editpersondetails?personId=" + data.personId
                })
                breadcrumbs.push({
                    active: true,
                    name: "Edit Appointment",
                    pathname: "/editappointment"
                })
                dispatch(saveCurrentBreadcrumbs(breadcrumbs));
            }
        }
        fetchData();
    }, [])

    const history = useHistory();

    const onSubmit = async (values: AppointmentDto) => {
        values.startdate = moment(values.startdate).format("YYYY-MM-DD")
        values.enddate = values.enddate ? moment(values.enddate).format("YYYY-MM-DD") : null
        setBusy(true);
        await UpdateAppointment(values).then((result) => {
            if (result != 0) {
                dispatch(personsApi.util.invalidateTags(['AuthorisedPersons']));
                dispatch(personsApi.util.invalidateTags(['PersonDetails']));
                setState({...state, entity: {...state.entity, ...values}});
                successToast("Saved Successfully", 2000);
            }
        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {
            setBusy(false);
        });
    }

    return (state.entity?.commissionId != 0 ? <React.Fragment>
            <Container fluid="md">
                <Row>
                    {(isBusy) ? <Spinner/> :
                            <AppointmentFormComponent
                                entity={state.entity} personId={state.entity?.personId!}
                                handleCancel={() => {
                                    history.goBack();
                                }}
                                handleIsValid={
                                    (valid: boolean) => {
                                        setValid(valid);
                                    }}
                                handleIsDirty={
                                    (dirty: boolean) => {
                                        setDirty(dirty);
                                    }}
                                handleSubmit={onSubmit} readonly={false}/>
                    }
                </Row>
                {isValid &&
                    <Row>
                        <CommissionListComponent commisionId={state.entity?.commissionId!}
                                                 appointmentData={state.commissionResult!}
                                                 canEditCommission={canEditCommission}
                                                 readOnly={isDirty}/>
                    </Row>
                }
            </Container>
        </React.Fragment>
        : (
            <span>Loading Appointment...</span>
        ))
}
export default AppointmentUpdateView;