import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import BasicTableControl from '../../controls/BasicTableControl.jsx';
import * as constants from '../../utils/constants';
import {useHistory, useLocation} from "react-router-dom";
import {useFetchDataOnLoad} from '../../hooks'
import PersonFormComponent from "./PersonFormComponent";


import {searchAppointmentData, searchPersonData} from '../../services/persondetailsdata'
import {PersonSearchResultModelDto} from "../../dto/PersonSearchResultModelDto";
import {UserContext} from "../../contexts/UserContext";
import {BreadcrumbType} from "../../components/AppBreadcrumb";
import {useAppDispatch} from "../../app/store";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";

interface IPersonalDetailState {
    canViewAppointment: boolean;
    canEditAppointment: boolean;
}

const PersonDetailView = () => {

    const {checkFeatureAccess, user} = useContext(UserContext);
    
    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const personId = (query.get("personId"));

    const breadcrumbs: BreadcrumbType[] = [{
        active: false
        ,
        name: "Person Details",
        pathname: "/persondetails"
    },
        {
            active: true,
            name: "View Person",
            pathname: "/viewpersondetails?personId=" + personId
        }];
    const dispatch = useAppDispatch();
    dispatch(saveCurrentBreadcrumbs(breadcrumbs));

    const history = useHistory();

    const Routepagenew = () => {
        history.push({
            pathname: '/addappointment',
            search: '?' + new URLSearchParams({personId: personId!}).toString()
        })
    }

    const Routepageold = () => {
        let path = '/persondetails';
        history.push(path);
    }

    const {result: person} = useFetchDataOnLoad(() => searchPersonData(personId), []);
    const [searchResult, setSearchResult] = useState<PersonSearchResultModelDto>(person);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        if(person.person) {
            person.person.personId = personId;
        }
        setSearchResult(person.person)
    }, [person.person]);
    // useEffect(() => {
    //     setSearchResult(person)
    // }, [person]);

    const {result: appointments} = useFetchDataOnLoad(() => searchAppointmentData(personId), []);
    const [appointmentResult, setappointmentResult] = useState<PersonSearchResultModelDto[]>(appointments);

    useEffect(() => {
        setappointmentResult(appointments)
    }, [appointments]);

    const [state, setState] = useState<IPersonalDetailState>({canViewAppointment: false, canEditAppointment: false});

    useEffect(() => {
        const canView: boolean = checkFeatureAccess(user, "View Appointment");
        const canEdit: boolean = checkFeatureAccess(user, "Edit Appointment");
        setState({canViewAppointment: canView, canEditAppointment: canEdit})
    }, [])
    
    const onSubmit = () => {
        
    }

    return (
        <React.Fragment>
            <>
                <Container fluid="md">
                {searchResult &&
                     <PersonFormComponent personDetails={searchResult}
                                    handleSubmit={() => onSubmit}
                                    handleCancel={() => history.goBack()}
                                    readonly={true}></PersonFormComponent>
                }
                                    </Container> 
            </>
        </React.Fragment>
    );
}
export default PersonDetailView;
