import {SearchPersonFilterDto} from "../../dto/SearchPersonFilterDto";
import {Form, Formik, useFormikContext} from "formik";
import * as Yup from 'yup';
import moment from 'moment';
import * as React from "react";
import {Button, Col, Row} from "react-bootstrap";
import FormikControl from "../../components/FormikControl";
import {ActSearchResultModelDto} from "../../dto/ActSearchResultModelDto";
import {SectionSearchResultModelDto} from "../../dto/SectionSearchResultModelDto";
import {
    getActs,
    getCitys,
    getCourtTribunallist,
    getSectionsByActId,
    getStates,
    getStatus
} from "../../services/referenceData";
import {CourtTribunalDto} from "../../dto/CourtTribunalDto";
import {StateDto} from "../../dto/StateDto";
import {CitySearchResultModelDto} from "../../dto/CitySearchResultModelDto";
import {CommissionStatusSearchResultModelDto} from "../../dto/CommissionStatusSearchResultModelDto";
import {savePersonSearchFilter} from "./personsSlice";
import {useEffect, useState} from "react";
import Spinner from "../../components/Loader/Spinner";
import {useLazyGetActiveActsQuery, useLazyGetActiveSectionsQuery} from "../../app/services/actApi";
import {downloadCommission} from "../../services/persondetailsdata";
import {PersonSearchCriteriaModelDto} from "../../dto/PersonSearchCriteriaModelDto";
import { Label } from "reactstrap";

interface PersonSearchState {
    fetching: boolean;
    acts?: ActSearchResultModelDto[];
    courts?: CourtTribunalDto[];
    states?: StateDto[];
    statuses?: CommissionStatusSearchResultModelDto[];
    // searchResult?: SearchPersonResultDto[];
    sections?: SectionSearchResultModelDto[];
    citys?: CitySearchResultModelDto[];
}

function PersonSearchFilter(props: {
    initialValues: SearchPersonFilterDto,
    handleCancel: () => void,
    handleSubmit: (entity: SearchPersonFilterDto) => void,
    handleIsReport: (isReport: boolean) => void;
    handleIsDirty: (isDirty: boolean) => void;
    // handleSearchSelection: ()
}) {

    const onSubmit = async (values: SearchPersonFilterDto, actions: any) => {
        if (values.isSearchCurrent == false && (values.fromDate == null || values.fromDate == ''
            || values.toDate == '' || values.toDate == null)) {
            return false;
        }
        if (values.isSearchCurrent == false && values.fromDate != null && values.fromDate != ''
            && values.toDate != '' && values.toDate != null) {
            if (moment(new Date(values.toDate)).toDate() < moment(new Date(values.fromDate)).toDate()) {
                return false;
            }
        }
        props.handleSubmit(values);
        actions.resetForm({
            values: values,
        });
        actions.setSubmitting(false);
    }
    const [getActs] = useLazyGetActiveActsQuery()
    const [getActiveSections] = useLazyGetActiveSectionsQuery()
    const [state, setState] = useState<PersonSearchState>({ fetching: true });
    const HandleIsDirtyContext = () => {
        const {dirty} = useFormikContext();
        React.useEffect(() => {
            props.handleIsDirty(dirty)
        }, [dirty]);
        return null;
    }
    useEffect(() => {
        const fetchData = async () => {
            setState({...state, fetching: true});
            const actlist = await getActs().unwrap();
            const courtList = await getCourtTribunallist();
            const stateList = await getStates();
            const statusList = await getStatus();
            var _sections: SectionSearchResultModelDto[] = [];
            if (props.initialValues.act != "0" && props.initialValues.act != "") {
                _sections =
                    await getActiveSections(props.initialValues.act).unwrap();
            }
            var _citys: CitySearchResultModelDto[] = [];
            if (props.initialValues.state != "0" && props.initialValues.state != "") {
                _citys = await getCitys(props.initialValues.state)
            }
            
            setState({
                ...state,
                acts: actlist,
                courts: courtList,
                states: stateList,
                statuses: statusList,
                sections: _sections,
                citys: _citys,
                fetching: false
            });
            //     if (personSearchDone) { 
            //         dispatch(fetchPersons(personSearchFilter))
            //         const searchResult: SearchPersonResultDto[] = await searchPersonsData(personSearchFilter!);
            //         setSearchResult(searchResult);
            //     }
        }
        fetchData();
        return () => {
            setState({
                fetching: false
            }); // This worked for me
        };
    }, [])

    const validationSchema = (props: any) =>
        Yup.lazy((values: any) =>
            Yup.object({
                fromDate: Yup.date().nullable()
                    .when('isSearchCurrent',
                        {
                            is: (value: boolean) => !value,
                            then: Yup.date().required('This is a required field')
                        }),

                toDate: Yup.date().when('isSearchCurrent',
                    {
                        is: (value: boolean) => !value,
                        then: Yup.date().min(Yup.ref('fromDate'), "To Date cannot be before From Date")
                            .required('This is a required field')
                    }).nullable()
            }))
    // {(state.fetching) ? <Spinner/> : 
    return (state.fetching) ? <Spinner/> : <Formik
        initialValues={props.initialValues}
        initialTouched={{
            fromDate: true,
            toDate: true,
        }}
        //validationSchema={validationSchema}
        onSubmit={onSubmit}
    >
        {
            formik => {
                const {
                    values, handleChange, setFieldValue,
                    dirty,
                    resetForm, isValid
                } = formik;
                return (
                    <Form>
                        <HandleIsDirtyContext/>
                        <Row>
                            <Col sm={4}>
                                <FormikControl control='radio' type='radio'
                                               label=''
                                               name='isSearchCurrent'
                                               options={[{
                                                   name: 'Current date',
                                                   id: 1,
                                                   value: true
                                               }, {name: 'Reporting period', id: 2, value: false}]}
                                               onChange={async (e: any) => {
                                                   const {value} = e.target;
                                                   //console.log(_sections);
                                                   if (value == "false") {
                                                       setFieldValue("isSearchCurrent", false);
                                                       props.handleIsReport(true);
                                                   } else {
                                                       setFieldValue("isSearchCurrent", true);
                                                       props.handleIsReport(false);
                                                   }
                                               }}
                                               value={values.isSearchCurrent}/>

                            </Col>
                            {(!values.isSearchCurrent) && <Col sm={3}>
                                <FormikControl control='date' type='text'
                                               label='From'
                                               value={values.fromDate !== null ? moment(new Date(values.fromDate)).toDate() : null}
                                                dateFormat name='fromDate'
                                                inputRequired={!values.isSearchCurrent}
                                />
                                {(values.fromDate == null || values.fromDate == '') && <Label className="text-danger">This is a required field</Label>}
                            </Col>}
                            {(!values.isSearchCurrent) && <Col sm={3}>
                                <FormikControl control='date' type='text' label='To'
                                               value={values.toDate ? moment(new Date(values.toDate)).toDate() : null}
                                                dateFormat name='toDate'
                                               inputRequired={!values.isSearchCurrent}
                                />
                                {(values.toDate == null || values.toDate == '') && <Label className="text-danger">This is a required field</Label>}
                                {(values.toDate != null && values.toDate != '' && values.fromDate != null && values.fromDate != '' &&
                                    moment(new Date(values.toDate)).toDate() < moment(new Date(values.fromDate)).toDate() )                                  
                                    && <Label className="text-danger">To Date cannot be before From Date</Label>}
                            </Col>}
                        </Row> <br/>
                        <Row> <Col sm={6}>
                            <FormikControl control='input' type='text'
                                           label='First Name'
                                           name='firstName'
                                           value={values.firstName}/>
                        </Col>
                            <Col sm={6}>
                                <FormikControl control='input' type='text'
                                               label='Surname' name='surname'
                                               value={values.surname}/>
                            </Col>
                        </Row> <br/>
                        <Row>
                            <Col sm={6}>
                                {
                                    <FormikControl control='select' label='Act'
                                                   name='act'
                                                   value={values.act}
                                                   options={state.acts?.map((item: ActSearchResultModelDto) => ({
                                                       id: item.actId,
                                                       name: item.description
                                                   }))}
                                                   onChange={async (e: any) => {
                                                       const {value} = e.target;
                                                       const _sections: SectionSearchResultModelDto[] = (value !== "0") ? await getActiveSections(value).unwrap() : [];
                                                       //console.log(_sections);
                                                       setFieldValue("act", value);
                                                       setFieldValue("section", "");
                                                       setState({
                                                           ...state,
                                                           sections: _sections
                                                       })
                                                   }}/>
                                }
                            </Col>
                            <Col sm={6}>
                                {
                                    <FormikControl control='select'
                                                   label='Section'
                                                   onChange={handleChange}
                                                   value={values.section}
                                                   name='section'
                                                   options={(state.sections) && (state.sections.map((item: SectionSearchResultModelDto) => ({
                                                       id: item.sectionId,
                                                       name: item.description
                                                   })))}/>
                                }
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={4}>
                                {
                                    <FormikControl control='select'
                                                   label='Court or Tribunal'
                                                   onChange={handleChange}
                                                   value={values.court}
                                                   name='court'
                                                   options={(state.courts?.map((item: CourtTribunalDto) => ({
                                                       id: item.courtTribunalId,
                                                       name: item.description
                                                   })))}/>
                                }
                            </Col>
                            <Col sm={4}>
                                {

                                    <FormikControl control='select'
                                                   label='State or Territory' name='state'
                                                   value={values.state}
                                                   options={(state.states?.map((item: StateDto) => ({
                                                       id: item.stateId,
                                                       name: item.code
                                                   })))}
                                                   onChange={async (e: any) => {
                                                       const {value} = e.target;
                                                       const _citys: CitySearchResultModelDto[] = (value !== "0") ? await getCitys(value) : [];
                                                       //console.log(_citys);
                                                       setFieldValue("state", value);
                                                       setFieldValue("city", "");
                                                       setState({
                                                           ...state,
                                                           citys: _citys
                                                       })
                                                   }}/>

                                }
                            </Col>
                            <Col sm={4}>
                                {

                                    <FormikControl control='select' label='City'
                                                   onChange={handleChange}
                                                   value={values.city}
                                                   name='city'
                                                   options={(state.citys) && (state.citys.map((item: CitySearchResultModelDto) => ({
                                                       id: item.cityId,
                                                       name: item.description
                                                   })))}/>
                                }
                            </Col>
                        </Row>
                        <br/>
                        {(values.isSearchCurrent) && <Row>
                            <Col md={4}>
                                <FormikControl control='select'
                                               label='Appointments'
                                               name='appointmentStatusId'
                                               value={values.appointmentStatusId}
                                               options={[{
                                                   id: 1,
                                                   name: 'Current'
                                               }, {id: 2, name: 'Inactive'}]}
                                               onChange={handleChange}
                                />
                            </Col>
                            <Col md={4} sm={6}>
                                <FormikControl control='select'
                                               label='Commission status'
                                               value={values.commissionStatusId}
                                               name='commissionStatusId'
                                               options={(state.statuses?.map((item: CommissionStatusSearchResultModelDto) => ({
                                                   id: item.commissionStatusId,
                                                   name: item.description
                                               })))}
                                               onChange={handleChange}
                                />
                            </Col>
                        </Row>}
                        <br/>
                        <Row>
                            <Col>
                                <Button variant="outline-info" type="submit"
                                        disabled={!isValid}
                                        className='mr-1 float-right'>Search</Button>
                                <Button variant='outline-info'
                                        type="reset"
                                        onClick={() => {
                                            resetForm({
                                                values: {
                                                    firstName: "",
                                                    surname: "",
                                                    act: "",
                                                    section: "",
                                                    court: "",
                                                    state: "",
                                                    city: "",
                                                    commissionStatusId: '',
                                                    appointmentStatusId: '',
                                                    isSearchCurrent: true,
                                                    fromDate: '',
                                                    toDate: ''
                                                }
                                            });
                                            props.handleCancel();
                                        }}
                                        className='float-right mr-2'>Clear
                                    Values</Button>
                            </Col>
                        </Row>
                        <br/> <Row> <Col> </Col> </Row>
                    </Form>
                )
            }
        }
    </Formik>

// } )
}

export default PersonSearchFilter;