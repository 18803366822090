import React, { useEffect, lazy, Suspense  } from 'react'
import { Route, Switch } from 'react-router'
import { PublicRoutes } from './PublicRoutes'
import { Redirect, useLocation } from 'react-router-dom'

export const PublicRouter = () => {

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0) // scroll to top on route change
  }, [pathname])



  return (
    <Suspense fallback={<div>Loading..</div>}>
    <Switch>
      {/* Remove tailing slashes in urls */}
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1) + window.location.search} />

      {PublicRoutes.map(({ children, ...props }) => (
        <Route key={props.path} {...props}>
          {children}
        </Route>
      ))}

      {/* ERROR route */}
      <Route>
        <Redirect to="/signin" />
      </Route>
    </Switch>
    </Suspense>
  )
}


