import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from 'formik';
import FormikControl from '../../components/FormikControl'
import * as Yup from 'yup';
import moment from 'moment';
import {getAllActs, getSectionsByActId, getStatus,} from '../../services/referenceData'
import {CommissionSectionUIModel} from "../../dto/CommissionSectionUIModel";
import {ActSearchResultModelDto} from "../../dto/ActSearchResultModelDto";
import {CommissionStatusSearchResultModelDto} from "../../dto/CommissionStatusSearchResultModelDto";
import {IApiAction} from "../../Interfaces";
import {IBaseFormPropsFunctions} from "../appointments/AppointmentFormComponent";
import {RouterPrompt} from "../../controls/RouterPrompt";
import AttachmentListComponent from "../attachments/AttachmentListComponent";
import {AppointmentDto} from "../../dto/AppointmentDto";

interface CommissionFormState extends IApiAction<CommissionSectionUIModel> {
    acts?: ActSearchResultModelDto[];
    statuses?: CommissionStatusSearchResultModelDto[];
}

interface CommissionFromProp extends IBaseFormPropsFunctions<CommissionSectionUIModel> {
    personId: string;
    appointmentId: string;
}

function CommissionFormComponent(props: CommissionFromProp) {

    const [state, setState] = useState<CommissionFormState>({
        entity: props.entity
    });

    useEffect(() => {
        const fetchData = async () => {
            const acts = await getAllActs();
            const statuses = await getStatus();
            setState({
                ...state, acts: acts, statuses: statuses
            });
        }
        fetchData();
    }, []);
    const handleOffence = (commissionStatusId: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        var status = commissionStatusId;

        let date_create = moment().toDate();

        let assign = (status == "1") ? date_create : null;
        let assignw = (status == "2") ? date_create : null;
        if (status == "1")
        {
            setFieldValue("consentstartdate", assign, true);
        }       
        else if (status == "2")
        {
            setFieldValue("consentenddate", assignw, true);
        }       
        setFieldValue("commissionStatusId", status);
        // schema.validate(props.entity);

    }

    const onSubmit = async (values: CommissionSectionUIModel, actions: any) => {
        props.handleSubmit(values);
        actions.setSubmitting(false);
        actions.resetForm({
            values: props.entity,
        });
    }

    const validationSchema = (props: any) =>
        Yup.lazy((values: any) =>
            Yup.object({
                act: Yup.number().required('This is a required field').positive('This is a required field').integer(),
                section: Yup.number().required('This is a required field').positive('This is a required field').integer(),
                commissionStatusId: Yup.number().required('This is a required field').positive('This is a required field').integer(),
                note: Yup.string().nullable().trim().matches(/^[ A-Za-z0-9_@./&\-,'"()]*$/, 'Please enter valid Note'),


                consentstartdate: Yup.date().nullable()
                    .transform((v: any) => (v instanceof Date ? v : null))
                    .test({
                        name: 'max',
                        exclusive: false,
                        params: {},
                        message: 'Active Commission dates must fall within the Appointment dates',
                        test: function (value) {
                            var activeFromDate = moment(value).format("YYYY-MM-DD")
                            var appointmentStartDate = moment(values.appointment?.startDate).format("YYYY-MM-DD")
                            var appointmentEndDate = moment(values.appointment?.endDate).format("YYYY-MM-DD")
                            if (value) {
                                if (value && values.appointment?.endDate === null) {
                                    return moment(activeFromDate).isSameOrAfter(appointmentStartDate)
                                } else {
                                    return (moment(activeFromDate).isSameOrAfter(appointmentStartDate) && moment(activeFromDate).isSameOrBefore(appointmentEndDate))
                                }
                            } else if ((values.commissionStatusId === 1 || values.commissionStatusId === 2) && (value === undefined || value === null)) {
                                return false
                            } else {
                                return true
                            }
                            // You can access the field with `this.parent`.
                        },
                    }),
                consentenddate: Yup.date().nullable()
                    .transform((v: any) => (v instanceof Date ? v : null))
                    .test({
                        name: 'max',
                        exclusive: false,
                        params: {},
                        message: 'Active Commission dates must fall within the Appointment dates',
                        test: function (value) {
                            var activeToDate = moment(value).format("YYYY-MM-DD")
                            var activeFromDate = values.consentstartdate !== null ? moment(new Date(values.consentstartdate)).toDate() : values.startDate && moment(values.startDate, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY')
                            var appointmentEndDate = moment(values.appointment?.endDate).format("YYYY-MM-DD")
                            if (value) {
                                if (value && values.appointment?.endDate === null) {
                                    return (moment(activeToDate).isSameOrAfter(moment(activeFromDate)))
                                    //return (moment(value).format("DD/MM/YYYY") >= moment(values.consentstartdate).format("DD/MM/YYYY"))
                                } else {
                                    return (moment(activeToDate).isSameOrAfter(moment(activeFromDate)) && moment(activeToDate).isSameOrBefore(moment(appointmentEndDate)))
                                    // return ((moment(value).format("DD/MM/YYYY") >= moment(values.consentstartdate).format("DD/MM/YYYY")) && (moment(value).format("DD/MM/YYYY") <= moment(this.parent.commEndDate).format("DD/MM/YYYY")))
                                }
                            } else if ((values.commissionStatusId === 2 && (value === undefined || value === null))) {
                                return false
                            } else {
                                return true
                            }
                            // You can access the field with `this.parent`.
                        },
                    }),
            }))

    return (
        <Container fluid="md">
            <Card className='table-card'>
                <Card.Body className='pb-0'>
                    <div style={{height: '100%'}}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={props.entity!}
                            onSubmit={onSubmit}
                            initialTouched={{
                                consentstartdate: true,
                                consentenddate: true
                            }}
                            validateOnBlur={true}
                            validateOnChange={true}
                            validationSchema={validationSchema}
                        >
                            {
                                formik => {
                                    const {
                                        values,
                                        handleChange,
                                        setFieldValue,
                                        setFieldTouched,
                                        resetForm,
                                        dirty,
                                        isValid
                                    } = formik;
                                    return (
                                        <Form>
                                            <RouterPrompt
                                                when={dirty && (props.entity?.commissionSectionId != 0)}
                                                title="Unsaved changes"
                                                cancelText="Close"
                                                okText="Discard"
                                                onOK={() => true}
                                                onCancel={() => false}
                                            /> {/*<PromptIfDirty/>*/}
                                            <fieldset disabled={props.readonly}>
                                                <Row>
                                                    {values.appointment &&
                                                        <Col sm={12}>
                                                            <Card.Text><b>Person: </b> {values.appointment?.givenNames + " " + values.appointment?.middleName + " " + values.appointment?.surnames}
                                                            </Card.Text>
                                                        </Col>
                                                    }
                                                </Row>
                                                <br/>
                                                <Row>
                                                    {values.appointment &&
                                                        <Col sm={4}>
                                                            <Card.Text><b>Court or
                                                                Tribunal:</b> {values.appointment?.courtTribunal}
                                                            </Card.Text>
                                                        </Col>
                                                    }
                                                    {values.appointment &&
                                                        <Col sm={4}>
                                                            <Card.Text><b>State or
                                                                Territory:</b> {values.appointment?.state}
                                                            </Card.Text>
                                                        </Col>
                                                    }
                                                    {values.appointment &&
                                                        <Col sm={4}>
                                                            <Card.Text><b>City:</b> {values.appointment?.city}
                                                            </Card.Text>
                                                        </Col>
                                                    }
                                                </Row>

                                                <br/>
                                                <Row>
                                                    {values.appointment &&
                                                        <Col sm={6}>
                                                            <Card.Text><b>Appointment Start
                                                                Date:</b> {values.startDate && moment(values.startDate, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY')}
                                                            </Card.Text>
                                                        </Col>
                                                    }
                                                    {values.appointment &&
                                                        <Col sm={6}>
                                                            <Card.Text><b>Appointment End
                                                                Date:</b> {values.appointment?.endDate && moment(values.appointment?.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY')}
                                                            </Card.Text>
                                                        </Col>
                                                    }

                                                </Row>
                                                <br/>
                                                <Row>
                                                    {values.appointment &&
                                                        <Col sm={6}>
                                                            <Card.Text><b>Position:</b> {values.appointment?.position}
                                                            </Card.Text>
                                                        </Col>
                                                    }
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col sm={6}>
                                                        {
                                                            <FormikControl control='select' label='Act' inputRequired
                                                                           name='act'
                                                                           readOnly={props.readonly}
                                                                           value={values.act}
                                                                           options={state.acts?.map((item: ActSearchResultModelDto) => ({
                                                                               id: item.actId,
                                                                               name: item.description,
                                                                               disabled: !item.active
                                                                           }))}
                                                                           onChange={async (e: any) => {
                                                                               const {value} = e.target;
                                                                               const _sections = (value !== "0") ? await getSectionsByActId(value) : [];
                                                                               //console.log(_sections);
                                                                               setFieldValue("act", value);
                                                                               setFieldValue("section", "");
                                                                               setFieldValue("sections", _sections);

                                                                           }}/>
                                                        }
                                                    </Col>
                                                    <Col sm={6}>
                                                        {
                                                            <FormikControl control='select' inputRequired
                                                                           label='Section' onChange={handleChange}
                                                                           readOnly={props.readonly}
                                                                           value={values.section}
                                                                           name='section'
                                                                           options={(values.sections) && (values.sections.map(item => ({
                                                                               id: item.sectionId,
                                                                               name: item.description,
                                                                               disabled: item.active != true
                                                                           })))}/>
                                                        }
                                                    </Col>
                                                </Row>
                                                <br/>

                                                <Row>
                                                    <Col sm={4}>
                                                        {
                                                            <FormikControl control='select' label='Commission Status'
                                                                           inputRequired
                                                                           value={values.commissionStatusId}
                                                                           readOnly={props.readonly}
                                                                           name='commissionStatusId'
                                                                           options={(state.statuses?.map((item: CommissionStatusSearchResultModelDto) => ({
                                                                               id: item.commissionStatusId,
                                                                               name: item.description
                                                                           })))}
                                                                           onChange={(e: any) => {
                                                                               handleChange(e);
                                                                               handleOffence(e.target.value, setFieldValue);
                                                                           }}
                                                            />
                                                        }
                                                    </Col>
                                                    {values.appointment &&
                                                        <Col sm={4}>
                                                            <FormikControl control='date' type='text'
                                                                           label='Active From Date'
                                                                           value={values.consentstartdate !== null ? moment(new Date(values.consentstartdate)).toDate() : values.startDate && moment(values.startDate, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY')}
                                                                           readOnly={props.readonly}
                                                                           dateFormat name='consentstartdate'
                                                                           inputRequired={values.commissionStatusId.toString() === "1" || values.commissionStatusId.toString() === "2" ? true : false}
                                                            />
                                                        </Col>}
                                                    <Col sm={4}>
                                                        <FormikControl control='date' type='text' label='Active To Date'
                                                                       value={values.consentenddate ? moment(new Date(values.consentenddate)).toDate() : values.endDate && moment(values.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format('DD/MM/YYYY')}
                                                                       readOnly={props.readonly}
                                                                       dateFormat name='consentenddate'
                                                                       inputRequired={values.commissionStatusId.toString() === "2" ? true : false}
                                                        />
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormikControl control='textarea' type='textarea' label='Note'
                                                                       value={values.note} name='note'/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <Button variant="outline-info" type='submit'
                                                                hidden={props.readonly}
                                                                disabled={props.readonly || (!(dirty && isValid))}
                                                                className='mr-1 float-right'>Save</Button>
                                                        <Button variant="outline-info"
                                                                hidden={true}
                                                                onClick={() => {
                                                                    if (values.Id == 0) {
                                                                        props.handleCancel()
                                                                    } else {
                                                                        resetForm();
                                                                    }
                                                                }} disabled={props.readonly || (!(dirty))}
                                                                className='mr-2 float-right'>Cancel</Button>
                                                    </Col>

                                                </Row>
                                                <br/>
                                                {!props.readonly && values.attachments.length > 0 &&
                                                    <AttachmentListComponent attachmentData={values.attachments}/>
                                                }
                                            </fieldset>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default CommissionFormComponent;
