import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {Modal} from "react-bootstrap";
import {CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CModal} from "@coreui/react";

// import { Modal } from "antd";

export function RouterPrompt(props: any) {
    const { when, onOK, onCancel, title, okText, cancelText } = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        if (when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname + prompt.search);
                setShowPrompt(true);
                return "true";
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
            <CModal visible={showPrompt} onClose={() => setShowPrompt(false)}>
                <CModalHeader>
                    <CModalTitle>{title}</CModalTitle>
                </CModalHeader>
                    <CModalBody>
                        Are you sure you want to discard unsaved changes? 
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => handleOK()}>
                        {okText}
                    </CButton>
                    <CButton color="primary" onClick={() => handleCancel()}>{cancelText}</CButton>
                </CModalFooter>
            </CModal> 
   ) : null;
}