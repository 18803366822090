import React, { useState, useContext } from 'react';
import { SignOutButton } from "./SignOutButton";
import { UserContext } from '../contexts/UserContext';

const SignInSignOutButton = () => {
    
    const { user } = useContext(UserContext)

    if (user) {
        return <SignOutButton />;
    } 
}

export default SignInSignOutButton;