import React, { useContext } from 'react';
import { UserContext } from './contexts/UserContext';
import CIcon from '@coreui/icons-react'
import {
  cilSpeedometer,
  cilSearch,
  cilSpreadsheet,cilUser
  
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'




const _nav = [
  {
    component: CNavItem,
    name: 'Authorised Person Search',
    to: '/authorisedpersonsearch',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    allowedroles: [
      'Administrator','Editor','General','Read Only']
  },
 
  {
    component: CNavItem,
    name: 'Person Details',
    to: '/persondetails',
    icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
    allowedroles: [
      'Administrator']
  },
  
  // {
  //   component: CNavItem,
  //   name: 'Reports',
  //   to: '/authorisedpersonsearch',
  //   icon: <CIcon icon={ cilSpreadsheet} customClassName="nav-icon" />,
  //   allowedroles: [
  //     'Administrator']
  // },
 
  {
    component: CNavGroup,
    name: 'Reference Data',
    allowedroles: [
      'Administrator'],
    
    icon: <CIcon icon={ cilSpreadsheet} customClassName="nav-icon" />,
    items: [
        // {
        //   component: CNavItem,
        //   name: 'Act Details',
        //   to: '/actdetails',
        //   icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
        //   allowedroles: [
        //     'Administrator']
        // },
        {
          component: CNavItem,
          name: 'Contact Details',
          to: '/contactdetails',
          icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
          allowedroles: [
            'Administrator']
        },
       
    ]
  },
 
  // {
  //   component: CNavItem,
  //   name: 'Change Password',
  //   to: '/changePassword',
  //   icon: <CIcon icon={ cilUser} customClassName="nav-icon" />,
  //   allowedroles: [
  //     'Administrator','Editor','General','Read Only']
  // },
  // {
  //   component: CNavItem,
  //   name: 'Sign Out',
  //   to: '/signout',
  //   icon: <CIcon icon={ cilUser} customClassName="nav-icon" />,
  //   allowedroles: [
  //     'Administrator','Editor','General','Read Only']
  // },

  
]
export default _nav
