import React from 'react';
import { Col, Row, Card } from "react-bootstrap";

const UserNotFound = () => {
    return (
        <React.Fragment>
            <Row>
                <Col md={12}>
                    <div className="auth-wrapper">
                        <div className="auth-content text-center">
                            <Card className='table-card'>
                                <Card.Header>
                                    <Card.Title as='h4'>Unauthorised Access</Card.Title>
                                </Card.Header>
                                <Card.Body className='pb-0'>
                                    <div style={{ height: '100%' }}>
                                        <p>
                                            You are not authorised to access RAPWOF application.
                                        </p>
                                        <p>
                                            Requests for access to the Register should be made using the <a href='/documents/RAPWoFUserAccessRequestForm.docx' download>User access request form</a> and emailed to <a href="mailto:orapwof@ag.gov.au">orapwof@ag.gov.au</a>.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </Col>
            </Row>
            

        </React.Fragment>
    );
}

export default UserNotFound;