import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {SearchPersonFilterDto} from "../../dto/SearchPersonFilterDto";
import {SearchPersonResultDto} from "../../dto/SearchPersonResultDto";
import {searchPersonsData} from "../../services/persondetailsdata";
import {RootState} from "../../app/store";

export interface PersonsState {
    visited: boolean;
    searchFilter: SearchPersonFilterDto;
    result: SearchPersonResultDto[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error?: string | null;
}
const initialState: PersonsState = {
    visited: false,
    searchFilter: {
        firstName: "", surname: "", act: "", section: "", court: "",
        state: "", city: "", commissionStatusId: '', appointmentStatusId: '', isSearchCurrent: true, fromDate:'', toDate:''
    },
    result: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null 
}
interface SearchFilterAndResultType {
   filter: SearchPersonFilterDto,
   result: SearchPersonResultDto[] 
}

export const personsSlice = createSlice({
    name: 'persons',
    initialState,
    reducers: {
        savePersonSearchFilterAndResult: (state, action: PayloadAction<SearchFilterAndResultType>) => {
            state.searchFilter = action.payload.filter;
            state.result = action.payload.result;
            state.visited = true;
        },
        savePersonSearchFilter: (state, action:PayloadAction<SearchPersonFilterDto>) => {
            state.searchFilter = action.payload;
            state.visited = true;
        },
        savePersonSearchResult: ( state, action: PayloadAction<SearchPersonResultDto[]>) => {
            state.result = action.payload;
        },
        resetPersonSearchFilter: (state, action:PayloadAction<SearchPersonFilterDto>) => {
            state.searchFilter = action.payload;
            state.visited = false;
        }
    },
    
})

export const {savePersonSearchFilter, savePersonSearchResult , resetPersonSearchFilter, savePersonSearchFilterAndResult} = personsSlice.actions;
export const selectPersonsFilter = (state: RootState) => state.persons.searchFilter;
export const selectPersonsVisited = (state: RootState) => state.persons.visited;
export const selectPersonsSearchResult = (state: RootState) => state.persons.result;
export const selectPersonsSearchError = (state: RootState) => state.persons.error;

export default personsSlice.reducer;