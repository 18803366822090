import React from 'react';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser'
import { msalConfig, loginRequest } from '../authConfig'
//import UserService from 'services/UserService'

import { Route, Switch } from 'react-router';


export default class AuthService {
  // Using singleton pattern so class can be used globally
  static getInstance () {
    if (!this.instance) {
      this.instance = new AuthService()
    }
    return this.instance
  }

  constructor () {
    this.msalApplication = new PublicClientApplication(msalConfig)
  }

  async handlePageLoadEvent () {
    // let exceptions bubble up to the caller to handle
    const authResult = await this.msalApplication.handleRedirectPromise()
    return this.handleRedirectResponse(authResult)
  }

  async handleRedirectResponse (authResult) {
    // if this page load is redirect from the Microsoft Identity platform then the
    // authResult will be populated. Otherwise null on other page loads.

    if (authResult !== null) {
      // save the fresh account info from the result.
      this.account = authResult.account

     // await UserService.getInstance().logUserSession()
    } else {
      // see if we have cached accounts.
      const currentAccounts = this.msalApplication.getAllAccounts()

      if (currentAccounts === null) {
        // no cached accounts.
        // user will need to click the sign-in button and redirect to login.
        return 
      } else if (currentAccounts.length > 1) {
        // there are some situations where the user may have multiple (different) cached logins.
        // this code sample does not cover that scenario but just logs a warning here.
        // this conditional block would need to be updated to support multiple accounts.
        // otherwise it will just grab the first one below.
        // eslint-disable-next-line no-console
        console.warn('Multiple accounts detected in MSAL account cache.')
        this.account = currentAccounts[0]
      } else if (currentAccounts.length === 1) {
        // we have exactly 1 cached account.
        // set the account info. user may not need to sign in.
        this.account = currentAccounts[0]
      }
    }

    return this.account
  }

  async getToken () {
    if (!this.account) {
      return
    }

    try {
      const tokenRequest = {
        account: this.account,
        scopes: loginRequest.scopes,
        authority: msalConfig.auth.authority
      }

      // msal will return the cached token if present, or call to get a new one
      // if it is expired or near expiring.
      return await this.msalApplication.acquireTokenSilent(tokenRequest)
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const tokenRedirectRequestPayload = {
          scopes: loginRequest.scopes,
          prompt: 'select_account',
          redirectUri: msalConfig.auth.redirectUri
        }

        await this.msalApplication.acquireTokenRedirect(tokenRedirectRequestPayload)
      } else if (error.errorCode === 'no_tokens_found') {
        // Redirect to sign in if get token failed
        window.location.href = '/'
      }
    }
  }

  async signIn () {
    let loginRedirectRequestPayload = {
      scopes: loginRequest.scopes,
      prompt: 'select_account',
      redirectUri: msalConfig.auth.redirectUri,
    }

    // this will redirect the web application to the Microsoft Identity platform sign in pages.
    // no code will execute after this point.
    return await this.msalApplication.loginRedirect(loginRedirectRequestPayload)
  }

  async signOut () {
    if (!this.account) {
      // no cached login to signout
      return
    }

    let accountInfo = this.msalApplication.getAccountByUsername(this.account?.username)

    if (accountInfo !== null) {
      let logoutRequestPayload = {
        account: accountInfo,
        redirectUri: msalConfig.auth.redirectUri,
        postLogoutRedirectUri: msalConfig.auth.redirectUri
      }

      return await this.msalApplication.logoutRedirect(logoutRequestPayload)
    }
  }
}
