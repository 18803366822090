import React, { Suspense, Fragment, lazy, useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Loader from "./components/Loader/Loader";
//import AdminLayout from "./layouts/";
import { UserContext } from "./contexts/UserContext";
import { BASE_URL } from "./config/constant";
import {DefaultLayout} from './layout/DefaultLayout';
import { Link } from 'react-router';
import RegistryFormComponent from "./features/registrys/RegistryFormComponent";
import AppointmentAddView from "./features/appointments/AppointmentAddView";
import AppointmentUpdateView from "./features/appointments/AppointmentUpdateView";
import PersonDetailView from "./features/persons/PersonDetailView";
import AppointmentInfoView from "./features/appointments/AppointmentInfoView";
import CommissionInfoView from "./features/commissions/CommissionInfoView";
import PersonSearchView from "./features/persons/PersonSearchView";
import CommissionEditView from './features/commissions/CommissionEditView';
import PersonUpdateView from 'features/persons/PersonUpdateView';
import RegistryAddView from "./features/registrys/RegistryAddView";

export const AppRoutes = ({ routes = [] }) => {
    const { checkUserRole, checkFeatureAccess, user } = useContext(UserContext);


    return (
        <Suspense fallback={<Loader />}>
            
            <Switch>
                {
                routes.map((route, i) => {
                
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;                    

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => {
                                // Don't render a page if the logged in user doesn't have access to it
                                //if (!checkUserRole(route.allowedroles)) {
                                  if (!checkFeatureAccess(user, route.name)) {
                                    return <Redirect key={i} to={BASE_URL} />
                                }

                                return (
                                    <Guard>
                                        <Layout>
                                            {route.routes
                                                ? <AppRoutes routes={route.routes} />
                                                : <DefaultLayout {...props} />}
                                        </Layout>
                                    </Guard>
                                )
                            }}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    )
};


const authorisedpersonsearch = React.lazy(() => import('./features/persons/AuthorisedPersonSearch'))

const actdetails = React.lazy(() => import('./views/actdetails'))

const contactdetails = React.lazy(() => import('./views/contactdetails'))

const viewregistrydetails = React.lazy(() => import('./features/registrys/RegistryEditView'))

// const  addregistry = React.lazy(() => import('./views/addregistry'))

const addphonenumber = React.lazy(() => import('./views/addphonenumber'))

const editphonenumber = React.lazy(() => import('./views/editphonenumber'))

// const persondetails = React.lazy(() => import('./views/persondetails'))

const addperson = React.lazy(() => import('./features/persons/PersonAddView'))

// const viewpersondetails = React.lazy(() => import('./views/viewpersondetails'))
// const editpersondetails = React.lazy(() => import('./views/editpersondetails'))

const addcommission = React.lazy(() => import('./features/commissions/CommissionAddView'))

const editcommissionSection = React.lazy(() => import('./features/commissions/CommissionEditView'))

// const viewCommissionSection = React.lazy(() => import('./views/viewCommissionSection'))

//const useradmin = React.lazy(() => import('./views/useradmin'))


//const sectiondetailing = React.lazy(() => import('./views/sectiondetails'))

//const SearchPage = ({ match, location }) => {
  //return (
   // <p>
    //  <strong>Location Props: </strong>
    //  {JSON.stringify(location, null, 2)}
  //  </p>
 // );
//}
//Location Props: { "pathname": "/search", "search": "?q=react", "hash": "", "key": "allc40" };

//const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))


const routes = [
  { path: '/', exact: true, name: 'Authorised Person Search', component: authorisedpersonsearch, allowedroles: [
    'Administrator','Editor','General','Read Only'
  ] },
  { path: '/authorisedpersonsearch', name: 'Authorised Person Search', component: authorisedpersonsearch, allowedroles: [
    'Administrator','Editor','General','Read Only'
  ] },
  { path: '/actdetails', name: 'Act Details', component: actdetails, allowedroles: [
    'Administrator',
  ] },
  { path: '/contactdetails', name: 'Contact Details', component: contactdetails, allowedroles: [
    'Administrator',
  ]},
  { path: '/viewregistrydetails', name: 'View Registry', component: viewregistrydetails, allowedroles: [
    'Administrator',
  ]},
  // { path: '/addregistry', name: 'Add Registry', component: addregistry, allowedroles: [
  //     'Administrator',
  //   ]},
  { path: '/addregistry', name: 'Add Registry', component: RegistryAddView, allowedroles: [
    'Administrator',
  ]},
  { path: '/addphonenumber', name: 'Add Registry Phone Number', component: addphonenumber, allowedroles: [
    'Administrator',
  ]},
  { path: '/editphonenumber', name: 'Edit Registry Phone Number', component: editphonenumber},

  { path: '/persondetails', name: 'Person Details', component: PersonSearchView
  // , allowedroles: [
  //   'Administrator',
  // ]
},
  { path: '/addperson', name: 'Add Person', component: addperson, allowedroles: [
    'Administrator',
  ]},

  { path: '/viewpersondetails', name: 'View Person', component: PersonDetailView, allowedroles: [
      'Administrator',
    ]},
  { path: '/editpersondetails', name: 'View Person', component: PersonUpdateView, allowedroles: [
    'Administrator',
  ]},
  { path: '/viewappointment', name: 'View Appointment', component: AppointmentInfoView, allowedroles: [
      'Administrator',
    ]},

  { path: '/addappointment', name: 'Add Appointment', key: 'test1', component: AppointmentAddView, allowedroles: [
      'Administrator',
    ]},
  { path: '/editappointment', name: 'Edit Appointment', component: AppointmentUpdateView, allowedroles: [
      'Administrator',
    ]},

  { path: '/addcommission', name: 'Add Commission', component: addcommission, allowedroles: [
    'Administrator',
  ]},
  { path: '/editcommissionSection', name: 'Edit Commission', component: CommissionEditView, allowedroles: [
    'Administrator',
  ]},
  { path: '/viewcommissionSection', name: 'View Commission', component: CommissionInfoView},


  { path: '/signin', exact: true, name: 'Home'  },
]

export default routes;
