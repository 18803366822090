import React, { createContext, useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { errorToast } from '../utils/toast'
import AuthService from '../services/AuthService'
import * as userService from '../services/userService';
import { useIsAuthenticated } from "@azure/msal-react";
import { useFetchDataOnLoad } from '../hooks/useFetchDataOnLoad'
import {
  getUserPermissions
}
  from '../services/permissionService'


import { useIdleTimer } from 'react-idle-timer'
import { defaultIdleTimeout, defaultIdleWarning } from '../config/constant'

export const UserContext = createContext()

export const UserContextProvider = ({ children }) => {
  const [authLoading, setAuthLoading] = useState(true)
  const [identity, setIdentity] = useState(null)
  const [user, setUser] = useState(null)
  const [userError, setUserError] = useState(null)
  
  const [userIdleTimeout, setUserIdleTimeout] = useState(defaultIdleTimeout)
  const [userIdleWarning, setUserIdleWarning] = useState(defaultIdleWarning)
  const [showIdleWarning, setShowIdleWarning] = useState(false)
  const { result: permissionByUser } = useFetchDataOnLoad(() => getUserPermissions(), []);

  
  const isAuthenticated = useIsAuthenticated();

  
  
 //Timer when to sign the user out
 const { start: startIdleSignOutTimeout } = useIdleTimer({
  timeout: userIdleWarning * 1000 * 60,
  promptTimeout: userIdleTimeout * 1000 * 60 - userIdleWarning * 1000 * 60,
  onIdle: async () => await handleSignOut(),
  onPrompt: () => setShowIdleWarning(true),
  onActive: () => setShowIdleWarning(false),
  debounce: 500,
  startManually: true,
  crossTab: {
    channelName: 'idleSignOutTimeout',
    emitOnAllTabs: true
  }
})


const endSessionwarning = useCallback(async () => {
  startIdleSignOutTimeout();
  setShowIdleWarning(false);
}, [])


  const handleSignIn = async () => {
    // Redirect to ADFS sign in
    return await AuthService.getInstance().signIn()
  }

  const handleSignOut = async () => {
    // Redirect to ADFS sign in
    return await AuthService.getInstance().signOut()
  }

  

  const getUserAsync = useCallback(async () => {
    try {
      // Get authentication token
      const token = await AuthService.getInstance().getToken()
//console.log(token);
      // Return if user is not logged in
      if (!token) {
        setAuthLoading(false)
        return
      }

      // fetch user profile
      const userProfile = await userService.getUserProfileByIdentity()
     
      // Update user in state
      setUser(userProfile)

      setUserIdleWarning(defaultIdleWarning)
      setUserIdleTimeout(defaultIdleTimeout)

      // Start idle timers if user was retrieved
      if (userProfile) {
      
        startIdleSignOutTimeout();

      }
     
    } catch (e) {
      errorToast('Failed to retrieve user details.')
      setUserError(e)
    } finally {
      setAuthLoading(false)
    }
  }, )

  // const allowedroles = [
  //   'Administrator',
  //   'Editor',
  //   'General',
  //   'Read Only',
  //   ];

 const checkUserRole  = (allowedroles) => allowedroles && user && user.roles.map(r=>r.description).filter(element => allowedroles.includes(element)).length > 0;

 const checkFeatureAccess = (user, name) => {
  let role = user.roles[0].description
  var feature = permissionByUser && permissionByUser.find(x => x.feature === name)
    if(feature) {
      if(role === "Administrator") {
        return feature.admin
      }
      if (role === "General") {
        return feature.general
      }
      if (role === "Editor") {
        return feature.editor
      }
      if (role === "Read Only") {
        return feature.readOnly
      }
      else {
        return false
      }
    }
}

  // Handles initial authentication check after login redirect
  useEffect(() => {
    const handlePageLoadAsync = async () => {
      setAuthLoading(true)

      // Handle page load (determine if logged in)
      const authResult = await AuthService.getInstance().handlePageLoadEvent()
//console.log(authResult);
      // Store authentication result
      setIdentity(authResult)

      if (!authResult) {
        setAuthLoading(false)
      }
    }

    handlePageLoadAsync()
  }, [])

  // Handles getting user after initial Authentication is complete
  useEffect(() => {
    // Fetch user if login was successful
   // console.log("user");
    //console.log(user);
    if (identity && !user) {
      getUserAsync()
    }
  }, [identity, user])

  const userContextValue = {
    user,
    isAuthLoading: authLoading,
    userError,
    showIdleWarning,
    extendSession : endSessionwarning,
    isAuthenticated,
    refreshUser: getUserAsync,
    signIn: handleSignIn,
    signOut: handleSignOut,
    checkUserRole,
    checkFeatureAccess
  }

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>
}
