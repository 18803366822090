import * as React from "react";
import {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useHistory, useLocation} from "react-router-dom";
import {useFetchDataOnLoad} from '../../hooks'
import {searchPersonData, updatePerson} from '../../services/persondetailsdata'
import PersonFormComponent from "./PersonFormComponent";
import {errorToast, successToast} from "utils/toast.js";
import {PersonDetailsDto} from "dto/PersonDetailsDto.js";
import {BreadcrumbType} from "../../components/AppBreadcrumb";
import {useAppDispatch} from "../../app/store";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";
import {personsApi} from "../../app/services/personApi";

const PersonUpdateView = () => {
    
    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    const personId = (query.get("personId"));
   const dispatch = useAppDispatch();

    const history = useHistory();

    const {result: person} = useFetchDataOnLoad(() => searchPersonData(personId), []);
    const [searchResult, setSearchResult] = useState<PersonDetailsDto|null>(person);

    useEffect(() => {
        if(person.person) {
            person.person.personId = personId;
        }
        setSearchResult(person.person)
        return () => {
            setSearchResult(null);
        }
    }, [person.person]);
   
    useEffect(() => {
        const breadcrumbs: BreadcrumbType[] = [{
            active: false
            ,
            name: "Person Details",
            pathname: "/persondetails"
        },
            {
                active: true,
                name: "Edit Person",
                pathname: "/editpersondetails?personId=" + personId
            }];
        dispatch(saveCurrentBreadcrumbs(breadcrumbs));
    }, [])

    const onSubmit = async (values: PersonDetailsDto) => {
        await updatePerson(values).then((result: any) => {
            if (result !== 0) {
                dispatch(personsApi.util.invalidateTags(['AuthorisedPersons']));
                dispatch(personsApi.util.invalidateTags(['PersonDetails']));
                setSearchResult(result)
                successToast('Saved Successfully');
            }
        }).catch((err: any) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {

        });
    }
    

    return (
        <React.Fragment>
            <>
                <Container fluid="md">
                    {searchResult &&
                        <PersonFormComponent
                            personDetails={searchResult}
                            handleCancel={() => {
                                history.goBack();
                            }}
                            handleSubmit={onSubmit}
                        readonly={false}></PersonFormComponent>
                    }
                </Container>
            </>
        </React.Fragment>
    );
}
export default PersonUpdateView;

