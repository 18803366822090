import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {AppointmentDto} from "../../dto/AppointmentDto";
import {IApiAction} from "../../Interfaces";
import {PersonSearchResultModelDto} from "../../dto/PersonSearchResultModelDto";
import api from "../../services/apiClient";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Form, Formik, FormikProps, useFormikContext} from "formik";
import * as Yup from 'yup';
import moment from "moment";
import {CourtTribunalDto} from "../../dto/CourtTribunalDto";
import FormikControl from "../../components/FormikControl";
import {StateDto} from "../../dto/StateDto";
import {ReferenceDataModelDto} from "../../dto/ReferenceDataModelDto";
import {RegistriesAllSearchResultModelDto} from "../../dto/RegistryDto";
import {OptionItem} from "../../dto/OptionItem";
import {DtoBase} from "../../dto/DtoBase";
import {
    useLazyGetCourtTribunalsQuery,
    useLazyGetEmploymentTypesQuery,
    useLazyGetEndReasonsQuery,
    useLazyGetPositionsQuery,
    useLazyGetStatesQuery,
    useLazyGetTermTypesQuery
} from "../../app/services/RefApi";
import {useLazyGetPersonQuery} from "../../app/services/personApi";
import {RouterPrompt} from 'controls/RouterPrompt';
import {ValidContext} from "./AppointmentUpdateView";

const HandleValidationContext = () => {
    // Grab values and submitForm from context
    const {valid, setValid} = useContext(ValidContext);
    const { isValid} = useFormikContext();
    
    React.useEffect(() => {
        setValid(isValid);
    }, [isValid]);
    return null;
}
export interface IBaseFormPropsFunctions<T extends DtoBase> extends IApiAction<T> {
    handleCancel: () => void
    handleSubmit: (entity: T) => void
    readonly: boolean;
}

interface AppointmentFormState extends IApiAction<AppointmentDto> {
    person?: PersonSearchResultModelDto;
    courts?: CourtTribunalDto[];
    states?: StateDto[];
    positions?: ReferenceDataModelDto[];
    employmentTypes?: ReferenceDataModelDto[];
    termTypes?: ReferenceDataModelDto[];
    endReasons?: ReferenceDataModelDto[];
    defaultStates?: OptionItem[];
    defaultCourts?: OptionItem[];
}

interface AppointmentFormProp extends IBaseFormPropsFunctions<AppointmentDto> {
    personId: string;
    handleIsDirty:(isDirty: boolean) => void;
    handleIsValid:(isValid: boolean) => void;
}

type AppointmentFormDto = AppointmentDto & {cityOptions: OptionItem[]}

function AppointmentFormComponent(props: AppointmentFormProp) {
    const [state, setState] = useState<AppointmentFormState>({
        entity: props.entity
    });

    // // @ts-ignore
    const [courtOptions, setCourtOptions] = useState<OptionItem[]>([]);
    const [stateOptions, setStateOptions] = useState<OptionItem[]>([]);
    const AppointmentFormEntity : AppointmentFormDto = {
        ...props.entity!, cityOptions: props.entity?.citys ? props.entity?.citys.map(item => ({
            id: item.cityId,
            name: item.description
        })): [] }
    // const [cityOptions, setCityOptions] = useState<OptionItem[]>(props.entity?.citys ? props.entity?.citys.map(item => ({
    //     id: item.cityId,
    //     name: item.description
    // })) : []);

    const [getCourts] = useLazyGetCourtTribunalsQuery();
    const [getStates] = useLazyGetStatesQuery();
    const [getPositions] = useLazyGetPositionsQuery();
    const [getEmploymentTypes] = useLazyGetEmploymentTypesQuery();
    const [getTermTypes] = useLazyGetTermTypesQuery();
    const [getEndReasons] = useLazyGetEndReasonsQuery();
    const [getPerson] = useLazyGetPersonQuery();//props.personId);
    
    const HandleFormContextChange = () => {
        const { dirty,isValid} = useFormikContext();
        React.useEffect(() => {
            props.handleIsDirty(dirty)
        }, [dirty]);
        React.useEffect(() => {
            props.handleIsValid(isValid)
        }, [isValid]);
        return null;
    }
    useEffect(() => {
        const fetchData = async () => {
            const person = await getPerson(props.personId).unwrap();
            const courts = await getCourts().unwrap();
            const states = await getStates().unwrap();
            const positions = await getPositions().unwrap();
            const employmentTypes = await getEmploymentTypes().unwrap();
            const termTypes = await getTermTypes().unwrap();
            const endReasons = await getEndReasons().unwrap();
            // @ts-ignore
            const courtList: OptionItem[] = courts == null ? [] : courts.map(item => ({
                id: item.courtTribunalId,
                name: item.description
            }));
            
            // @ts-ignore
            const stateList: OptionItem[] = states == null ? [] : states.map(item => ({
                id: item.stateId, name: item.code
            }));

            setState({
                ...state,
                person: person,
                courts: courts,
                defaultCourts: courtList,
                states: states,
                defaultStates: stateList,
                positions: positions,
                employmentTypes: employmentTypes,
                termTypes: termTypes,
                endReasons: endReasons
            });
            setCourtOptions(courtList);
            setStateOptions(stateList);
        };
        fetchData();
    }, []);

    const endReasonRequired = (endDate: string | null | undefined) => {
        return endDate === '' || endDate === null ? false : true;
    }
    
    const onSubmit = async (values: AppointmentFormDto, actions: any) => {
        const { cityOptions, ...appointmentData } = values;
        props.handleSubmit(appointmentData);
        // actions.setSubmitting(false);
        actions.resetForm({
            values: props.entity,
        });
    }
    const schema = Yup.object<AppointmentDto>().shape({
        court: Yup.number().required('This is a required field').positive('This is a required field').integer(),
        state: Yup.number().required('This is a required field').positive('This is a required field').integer(),
        city: Yup.number().required('This is a required field').positive('This is a required field').integer(),
        startdate: Yup.date().nullable().required('This is a required field'),
        enddate: Yup.date()
            .nullable()
            .default(undefined)
            .transform((startdate: any) => startdate == '' ? null : startdate)
            .when('startdate', (startdate: any, enddate: any) => {
                if (startdate) {
                    return enddate && Yup.date().nullable().min(startdate, "End Date cannot be before Start Date")

                }
            }),
        position: Yup.number().required('This is a required field').positive('This is a required field').integer(),
        endreason: Yup.number()
            .nullable()
            .when('enddate', {
                is: (value: any) => value != undefined,
                then: Yup.number().required('This is a required field').positive('This is a required field').integer()
            })
    });
    //@ts-ignore
    const validationSchema = (props: any) =>
        Yup.lazy((values: any) =>
            Yup.object({

                court: Yup.number().required('This is a required field').positive('This is a required field').integer(),
                state: Yup.number().required('This is a required field').positive('This is a required field').integer(),
                city: Yup.number().required('This is a required field').positive('This is a required field').integer(),
                startdate: Yup.date().nullable().required('This is a required field'),
                enddate: Yup.date()
                    .nullable()
                    .default(undefined)
                    .transform((startdate: any) => startdate == '' ? null : startdate)
                    .when('startdate', (startdate: any, enddate: any) => {
                        if (startdate) {
                            return enddate && Yup.date().nullable().min(startdate, "End Date cannot be before Start Date")

                        }
                    }),

                // .when("startdate",
                //     (startdate, Yup) => startdate && Yup.min(startdate, "End Date cannot be before Start Date")),
                position: Yup.number().required('This is a required field').positive('This is a required field').integer(),
                endreason: Yup.number()
                    .nullable()
                    .when('enddate', {
                        is: (value: any) => value != undefined,
                        then: Yup.number().required('This is a required field').positive('This is a required field').integer()
                    })
            }))
    return <Container fluid="md">
        <Card className='table-card'>
            <Card.Body className='pb-0'>
                <div style={{height: '100%'}}>
                    <Formik enableReinitialize={true} 
                            initialValues={AppointmentFormEntity}
                            onSubmit={onSubmit}
                            initialTouched={{
                                startdate: true,
                                position: true,
                                enddate: true,
                                endreason: true
                            }}
                            validationSchema={validationSchema}
                            validateOnMount={true}>
                        {formik => {
                            const {
                                values,
                                handleChange,
                                setFieldValue,
                                setFieldTouched,
                                resetForm,
                                dirty,
                                isValid
                            } = formik;
                            // @ts-ignore
                            return (
                                <Form>
                                    <RouterPrompt
                                        when={dirty && (props.entity?.commissionId != 0)}
                                        title="Unsaved changes"
                                        cancelText="Close"
                                        okText="Discard"
                                        onOK={() => true}
                                        onCancel={() => false}
                                    /> {/*<PromptIfDirty/>*/}
                                   <HandleValidationContext /> 
                                    <HandleFormContextChange  />
                                    <fieldset disabled={props.readonly}>
                                        <Row> {state.person?.person &&
                                            <Col sm={12}>
                                                <Card.Text><b>Name: {state.person.person.firstName + " " + state.person.person.middleName + " " + state.person.person.surname} </b>
                                                </Card.Text>
                                            </Col>}
                                        </Row> <br/>
                                        <Row> <Col sm={4}>
                                            {
                                                <FormikControl control='select' inputRequired
                                                               label='Court or Tribunal'
                                                               readOnly={props.readonly}
                                                               value={values.court} name='court'
                                                               options={courtOptions?.map(item => ({
                                                                   id: item.id,
                                                                   name: item.name
                                                               }))}
                                                               onChange={async (e: any) => {
                                                                   const {value} = e.target;
                                                                   setFieldValue("court", value);

                                                                   const courtCode = ((value == '') || (value == ' ')) ? '0' : value;

                                                                   let stateCode = ((values.state == '') || (values.state == ' ')) ? '0' : values.state;
                                                                   // if help to reset 
                                                                   if (courtCode == '0') {
                                                                       stateCode = '0';
                                                                       setFieldValue("court", "");
                                                                       setFieldValue("state", "");
                                                                       setFieldValue("city", "");
                                                                       setStateOptions(state.defaultStates!);
                                                                       setCourtOptions(state.defaultCourts!);
                                                                       setFieldValue("cityOptions", []);
                                                                       // setCityOptions([]);
                                                                       return
                                                                   }

                                                                   const _registries: RegistriesAllSearchResultModelDto[] = (value !== "0") ?
                                                                       // @ts-ignore
                                                                       await api.get('GetActiveRegistries?courttribunalid=' + courtCode + '&cityid=0&stateid=' + stateCode)
                                                                       : [];

                                                                   // @ts-ignore
                                                                   const stateList: OptionItem[] = _registries.map(item => ({
                                                                       id: item.stateId,
                                                                       name: item.state
                                                                   }));
                                                                   const uniqueIds = new Set();
                                                                   const stateUniuqeSet = stateList.filter(element => {
                                                                       const isDuplicate = uniqueIds.has(element.id);
                                                                       uniqueIds.add(element.id);
                                                                       return !isDuplicate;
                                                                   });
                                                                   setStateOptions(stateUniuqeSet);

                                                                   // @ts-ignore
                                                                   const cityList: OptionItem[] = _registries.map(item => ({
                                                                       id: item.cityId,
                                                                       name: item.city
                                                                   }));
                                                                   const uniqueCityIds = new Set();
                                                                   const cityUniuqeSet = cityList.filter(element => {
                                                                       const isDuplicate = uniqueCityIds.has(element.id);
                                                                       uniqueCityIds.add(element.id);
                                                                       return !isDuplicate;
                                                                   });
                                                                   setFieldValue("cityOptions", cityUniuqeSet);
                                                                   // setCityOptions(cityUniuqeSet);

                                                                   setFieldValue("city", "");

                                                               }}/>
                                            }
                                        </Col>
                                            <Col sm={4}> {
                                                <FormikControl control='select' inputRequired label='State or Territory'
                                                               name='state'
                                                               value={values.state}
                                                               readOnly={props.readonly}
                                                               options={(stateOptions?.map(item => ({
                                                                   id: item.id,
                                                                   name: item.name
                                                               })))}
                                                               onChange={async (e: any) => {
                                                                   const {value} = e.target;
                                                                   setFieldValue("state", value);
                                                                   let courtCode = ((values.court == '') || (values.court == ' ')) ? '0' : values.court;
                                                                   const stateCode = ((value == '') || (value == ' ')) ? '0' : value;
                                                                   if (stateCode == '0') {
                                                                       courtCode = '0';
                                                                       setFieldValue("court", "");
                                                                       setFieldValue("state", "");
                                                                       setFieldValue("city", "");
                                                                       setStateOptions(state.defaultStates!);
                                                                       setCourtOptions(state.defaultCourts!);
                                                                       setFieldValue("cityOptions", [])
                                                                       // setCityOptions([]);
                                                                       return
                                                                   }
                                                                   const requestUrl = 'GetActiveRegistries?courttribunalid=' + courtCode + '&cityid=0&stateid=' + stateCode;
                                                                   const _registries: RegistriesAllSearchResultModelDto[] = (value !== "0") ?
                                                                       // @ts-ignore
                                                                       await api.get(requestUrl)
                                                                       : [];
                                                                   const courtList: OptionItem[] = _registries.map(item => ({
                                                                       id: item.courtTribunalId,
                                                                       name: item.courtTribunal
                                                                   }));
                                                                   const uniqueCourtIds = new Set();
                                                                   const courtUniuqeSet = courtList.filter(element => {
                                                                       const isDuplicate = uniqueCourtIds.has(element.id);
                                                                       uniqueCourtIds.add(element.id);
                                                                       return !isDuplicate;
                                                                   });
                                                                   setCourtOptions(Array.from(new Set(courtUniuqeSet)));

                                                                   // @ts-ignore
                                                                   const cityList: OptionItem[] = _registries.map(item => ({
                                                                       id: item.cityId,
                                                                       name: item.city
                                                                   }));
                                                                   const uniqueCityIds = new Set();
                                                                   const cityUniuqeSet = cityList.filter(element => {
                                                                       const isDuplicate = uniqueCityIds.has(element.id);
                                                                       uniqueCityIds.add(element.id);
                                                                       return !isDuplicate;
                                                                   });
                                                                   setFieldValue("cityOptions", cityUniuqeSet);
                                                                   // setCityOptions(cityUniuqeSet);

                                                                   setFieldValue("city", "");
                                                               }}/>

                                            }
                                            </Col>
                                            <Col sm={4}> {
                                                <FormikControl control='select' inputRequired label='City'
                                                               onChange={handleChange} value={values.city}
                                                               name='city'
                                                               readOnly={props.readonly}
                                                               options={(values.cityOptions?.map(item => ({
                                                                   id: item.id,
                                                                   name: item.name
                                                               })))}/>
                                            }
                                            </Col>
                                        </Row> <br/>
                                        <Row>
                                            <Col sm={4}>
                                                <FormikControl control='date' type='text' label='Start Date'
                                                               readOnly={props.readonly}
                                                               inputRequired
                                                               value={values.startdate ? moment(values.startdate).toDate() : null}
                                                               name='startdate'/>
                                            </Col>
                                            <Col sm={4}>
                                                <FormikControl control='date' type='text' label='End Date'
                                                               readOnly={props.readonly}
                                                               value={values.enddate ? moment(values.enddate).toDate() : null}
                                                               name='enddate'
                                                />
                                            </Col>
                                            <Col sm={4}> {state.person?.person &&
                                                <FormikControl control='select' inputRequired label='Position'
                                                               name='position'
                                                               readOnly={props.readonly}
                                                               value={values.position}
                                                               options={(state.positions?.map(item => ({
                                                                   id: item.id,
                                                                   name: item.description
                                                               })))}
                                                               onChange={handleChange}/>
                                            }
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col sm={4}>
                                                <FormikControl control='select' label='Employment Type'
                                                               name='employmenttype'
                                                               readOnly={props.readonly}
                                                               value={values.employmenttype}
                                                               options={(state.employmentTypes?.map(item => ({
                                                                   id: item.id,
                                                                   name: item.description
                                                               })))}
                                                               onChange={handleChange}/>
                                                {/* <FormikControl control='input' type='text' label='Employment Type' name='employmenttype' value={values.employmenttype} /> */}
                                            </Col>
                                            <Col sm={4}>
                                                <FormikControl control='select' label='Term Type' name='termtype'
                                                               value={values.termtype}
                                                               readOnly={props.readonly}
                                                               options={(state.termTypes?.map(item => ({
                                                                   id: item.id,
                                                                   name: item.description
                                                               })))}
                                                               onChange={handleChange}/>
                                                {/* <FormikControl control='input' type='text' label='Term Type' name='termtype' value={values.termtype} /> */}
                                            </Col>
                                            <Col sm={4}>
                                                <FormikControl control='select'
                                                               inputRequired={endReasonRequired(values.enddate)}
                                                               readOnly={props.readonly}
                                                               label='End Reason' name='endreason'
                                                               value={values.endreason}
                                                               options={(state.endReasons?.map(item => ({
                                                                   id: item.id,
                                                                   name: item.description
                                                               })))}
                                                               onChange={handleChange}/>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Button variant="outline-info" type='submit'
                                                        hidden={props.readonly}
                                                        disabled={props.readonly || (!(dirty && isValid))}
                                                        className='mr-1 float-right'>Save</Button>
                                                <Button variant="outline-info"
                                                        hidden={true}
                                                        onClick={() => {
                                                            if (values.commissionId == 0) {
                                                                props.handleCancel()
                                                            } else {
                                                                resetForm();
                                                            }
                                                        }} disabled={props.readonly || (!(dirty))}
                                                        className='mr-2 float-right'>Cancel</Button>
                                            </Col>
                                        </Row>
                                        <br/>
                                    </fieldset>
                                </Form>
                            )
                        }
                        }
                    </Formik>
                </div>
            </Card.Body>
        </Card>
    </Container>;
}

export default AppointmentFormComponent;