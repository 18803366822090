import * as React from 'react';
import {IApiAction} from "../../Interfaces";
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import api from "../../services/apiClient";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {errorToast} from "../../utils/toast";
import FormikControl from "../../components/FormikControl";
import {StateDto} from "../../dto/StateDto";
import {RegistryDto} from "../../dto/RegistryDto";
import {createOrUpdateRegistryDetails, searchRegistriesData} from "../../services/contactdetailsdata";
import {OptionItem} from "../../dto/OptionItem";
import {ContactTypeDto} from "../../dto/ContactTypeDto";
import Alert from "react-bootstrap/Alert";
import RegistryFormComponent from "./RegistryFormComponent";
import {useState} from "react";
import {useAppDispatch} from "../../app/store";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";


interface RegistryFormState extends IApiAction<RegistryDto> {
    fetching: boolean;
    states?: StateDto[];
    contacttypes?: ContactTypeDto[];
    show?: boolean;
}
const initialValues: RegistryDto = {
    registryId: 0, contactTypeId: "", city: "", courtTribunal: "", state: "", line1: "", line2: "", line3: "",
    namePositionTitle: "", phoneNumber: "", postCode: "", court_State: "", court_City: "", sortOrder: ""
}
const RegistryAddView =() => { 

    const [showError, setShowError] = useState<boolean>(false);
    const history = useHistory();
    
    const routeChange = (registryId: number) => {
        history.push({
            pathname: '/viewregistrydetails',
            search: '?' + new URLSearchParams({registryId: registryId.toString()}).toString()
        })
    }
    const dispatch = useAppDispatch();
    const breadcrumbs = [
        {
            active: false,
            name: "Contact Details",
            pathname: "/contactdetails"
        },
        {
        active: true,
        name: "Add Registry",
        pathname: "/addregistry"
    }];
    dispatch(saveCurrentBreadcrumbs(breadcrumbs));
    const onSubmit = async (values: RegistryDto) => {
        //console.log(values);
        await createOrUpdateRegistryDetails(values).then((result) => {
            if (result.registryId != null) {

                values.registryId = result.registryId;

                if (values.registryId !== 0) {
                    routeChange(values.registryId!);

                } else {
                    setShowError(true);
                }
            }
            ;
        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get submission search results.')
        }).finally(() => {
        });
    }
       return <React.Fragment>
            <Container fluid="md">
                <Row>
                    <RegistryFormComponent entity={initialValues} handleCancel={
                        ()=> {history.goBack();}
                    } handleSubmit={onSubmit} readonly={false}/>
                </Row>
                <Row>
                    <Col xs={6}>

                        <Alert variant="danger" onClose={() => setShowError(false)}
                                show={showError}>
                            <Alert.Heading>
                                <strong className="me-auto">Registry already exists</strong>
                            </Alert.Heading>

                        </Alert>
                    </Col>

                </Row>
                <br/>
            </Container>
        
        </React.Fragment>
}

export default RegistryAddView;