import * as React from "react";
import {Redirect, useHistory} from "react-router-dom";
import {AppointmentDto} from "../../dto/AppointmentDto";
import {IApiAction} from "../../Interfaces";
import moment from "moment";
import {
    getcommissionSectionbycommissionSectionid,
    searchAppointmentDatabyid,
    UpdateAppointment
} from "../../services/persondetailsdata";
import {errorToast} from "../../utils/toast";
import AppointmentFormComponent from "../appointments/AppointmentFormComponent";
import {CommissionResultModelDto} from "../../dto/CommissionResultModelDto";
// @ts-ignore
import FileSaver from "file-saver";
import {Button, Card, Col, Row, Container} from "react-bootstrap";
import CommissionListComponent from "./CommissionListComponent";
import {useLocation} from 'react-router-dom'
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {getAttachment} from "../../services/attachmentService";
import {useAppDispatch} from "../../app/store";
import {BreadcrumbType} from "../../components/AppBreadcrumb";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";
import {CommissionSectionUIModel} from "../../dto/CommissionSectionUIModel";
import CommissionFormComponent from "./CommissionFormComponent";

const CommissionInfoView = () => {
    const {checkFeatureAccess, user} = useContext(UserContext);

    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const commissionSectionId = (query.get("commissionSectionId"));
    const [entity, setEntity] = useState<CommissionSectionUIModel>(
        {
            commissionSectionId: 0,
            act: "",
            section: "",
            consentstartdate: "",
            consentenddate: "",
            commissionStatusId: "",
            sections: [],
            note: "",
            attachments: [],
            createdDate: "",
            createdId: "",
            startDate: '',
            endDate: '',
            appointmentId: ''

        }
    );
    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchData = async () => {

            const data = await getcommissionSectionbycommissionSectionid(commissionSectionId);

            if (data) {
                setEntity({
                    commissionSection: data,
                    commissionSectionId: Number(commissionSectionId),
                    act: data.act.toString(),
                    section: data.section.toString(),
                    consentstartdate: data.consentstartdate,
                    consentenddate: data.consentenddate,
                    commissionStatusId: data.commissionStatusId.toString(),
                    sections: data.sections,
                    note: data.note,
                    attachments: data.attachments,
                    createdDate: data.createdDate,
                    createdId: data.createdId,
                    startDate: data.commissions!.startDate,
                    endDate: data.commissions!.endDate,
                    appointmentId: data.commissions!.commissionId.toString(),
                    appointment: {
                        commissionId: data.commissions.commissionId,
                        personId: data.commissions.personId,
                        startDate: data.commissions.startDate,
                        endDate: data.commissions.endDate,
                        title: data.commissions.title,
                        givenNames: data.commissions.givenNames,
                        surnames: data.commissions.surnames,
                        middleName: data.commissions.middleName,
                        city: data.commissions.city,
                        courtTribunal: data.commissions.courtTribunal,
                        state: data.commissions.state,
                        position: data.commissions.position
                    }
                });
                const breadcrumbs: BreadcrumbType[] = [];
                breadcrumbs.push({
                    active: false,
                    name: "Person Details",
                    pathname: "/persondetails"
                })
                breadcrumbs.push({
                    active: false,
                    name: "View Person",
                    pathname: "/viewpersondetails?personId=" + data.commissions.personId
                });
                breadcrumbs.push({
                    active: false,
                    name: "View Appointment",
                    pathname: "/viewappointment?commissionId=" + data.commissions.commissionId
                });
                breadcrumbs.push({
                    active: true,
                    name: "View Commission",
                    pathname: "/editcommissionSection?commissionSectionId" + commissionSectionId
                })
                dispatch(saveCurrentBreadcrumbs(breadcrumbs));

            }
        }
        fetchData();
    }, []);


    const downloadAttachment = async (attachment: any, e: any) => {
        e.preventDefault();
        const name = attachment.description;
        await getAttachment(attachment.attachmentId).then((result) => {
            if (result !== 0) {
                var blob = new Blob([result], {type: "application/pdf;charset=utf-8"});
                FileSaver.saveAs(blob, name);
            }

        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {

        });
    }

    return (
        <React.Fragment>
            <Container fluid="md">
                <Row>
                    <CommissionFormComponent
                        entity={entity}
                        personId={"0"} appointmentId={"0"}
                        handleCancel={() => {
                        }}
                        handleSubmit={() => {
                        }} readonly={true}
                    /></Row>
                {entity.attachments && entity.attachments.length > 0 &&
                    <Row>
                        <Col md={12}>
                            <h5>Attachments</h5>
                        </Col>
                        <Col md={12}>
                            <ul>
                                {entity.attachments.map((item: any, i: number) => {
                                    return <li key={i}><a href="#"
                                                          onClick={(e: any) => downloadAttachment(item, e)}>{item.description}</a>
                                    </li>
                                })}
                            </ul>
                        </Col>
                    </Row>
                }

            </Container>


        </React.Fragment>

    );
}
export default CommissionInfoView;