import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux'
import {store} from './app/store'
import 'react-app-polyfill/stable'
import 'core-js'
import { MsalProvider } from "@azure/msal-react";

import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(msalConfig);

 const rootElement = document.getElementById('root');



ReactDOM.render(

  
    <Provider store={store}>
    <App />
  </Provider>
,

  rootElement);

  

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

