import * as React from "react";
import {Redirect, useHistory} from "react-router-dom";
import {AppointmentDto} from "../../dto/AppointmentDto";
import {IApiAction} from "../../Interfaces";
import moment from "moment";
import {searchAppointmentDatabyid, UpdateAppointment} from "../../services/persondetailsdata";
import {errorToast} from "../../utils/toast";
import AppointmentFormComponent from "./AppointmentFormComponent";
import {CommissionResultModelDto} from "../../dto/CommissionResultModelDto";
// @ts-ignore
import FileSaver from "file-saver";
import {Button, Card, Col, Row, Container} from "react-bootstrap";
import CommissionListComponent from "../commissions/CommissionListComponent";
import {useLocation} from 'react-router-dom'
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {useAppDispatch} from "../../app/store";
import {BreadcrumbType} from "../../components/AppBreadcrumb";
import {saveCurrentBreadcrumbs} from "../../app/navigationSlice";
import {personsApi} from "../../app/services/personApi";


interface AppointmentInfoState extends IApiAction<AppointmentDto> {
    commissionResult?: CommissionResultModelDto;
    attachmentData?: any;
    attachmentName?: string;
    canAttach?: boolean;
    selectedRows?: any[];
}

const AppointmentInfoView = () => {

    const breadcrumbs: BreadcrumbType[] = [];

    const {checkFeatureAccess, user} = useContext(UserContext);

    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const commissionId = (query.get("commissionId"));
    const [state, setState] = useState<AppointmentInfoState>({entity: {commissionId: 0}});

    const canEditCommission = checkFeatureAccess(user, 'Edit Commission');
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const data = await searchAppointmentDatabyid(commissionId);

            if (data) {
                setState({
                    entity:
                        {
                            commissionId: data.commissionId,
                            court: data.courtTribunalId.toString(),
                            state: data.stateId.toString(),
                            city: data.cityId.toString(),
                            startdate: data.startDate,
                            enddate: data.endDate,
                            position: data.arPosition?.toString(),
                            endreason: data.endReason,
                            employmenttype: data.employmentType?.toString(),
                            termtype: data.arTermTypeCode?.toString(),
                            personId: data.personId?.toString(),
                            citys: data.citys,
                            createdDate: data.createdDate,
                            createdId: data.createdId
                        }
                    , commissionResult: data
                });
            }
            breadcrumbs.push({
                active: false,
                name: "Person Details",
                pathname: "/persondetails"
            })
            breadcrumbs.push({
                active: false,
                name: "View Person",
                pathname: "/viewpersondetails?personId=" + data.personId
            });
            breadcrumbs.push({
                active: true,
                name: "View Appointment",
                pathname: "/viewappointment"
            })
            dispatch(saveCurrentBreadcrumbs(breadcrumbs));
        }
        fetchData();
    }, [])

    const history = useHistory();

    const Routepageold = () => {
        let path = '/persondetails';
        history.push(path);
    }

    const Routepagenew = () => {
        // console.log('route' + commissionId)
        history.push({
            pathname: '/addcommission',
            // @ts-ignore
            search: '?' + new URLSearchParams({commissionId: commissionId}).toString()
        })
    }

    const Routepageback = (personId: string) => {

        history.push({
            pathname: '/viewpersondetails',
            search: '?' + new URLSearchParams({personId: personId}).toString()
        })
    }
    const onSubmit = async (values: AppointmentDto) => {
        values.startdate = moment(values.startdate).format("YYYY-MM-DD")
        values.enddate = values.enddate ? moment(values.enddate).format("YYYY-MM-DD") : null
        await UpdateAppointment(values).then((result) => {
            if (result != 0) {
                dispatch(personsApi.util.invalidateTags(['AuthorisedPersons']));
                dispatch(personsApi.util.invalidateTags(['PersonDetails']));
                Routepageback(result);
            }
        }).catch((err) => {
            console.log("err -> ", JSON.stringify(err));
            errorToast('Failed to get Id.')
        }).finally(() => {
            //setFetching(false)
        });
    }

    return (state.entity?.commissionId != 0 ? <React.Fragment>
            <Container fluid="md">
                <Row>
                    <AppointmentFormComponent
                        entity={state.entity} personId={state.entity?.personId!}
                        handleCancel={() => {
                        }}
                        handleIsDirty={()=>{}}
                        handleSubmit={onSubmit}
                        readonly={true} 
                        handleIsValid={()=>{}}
                    ></AppointmentFormComponent>
                </Row>
                <Row>
                    <CommissionListComponent commisionId={state.entity?.commissionId!}
                                             appointmentData={state.commissionResult!}
                                             canEditCommission={canEditCommission}
                    readOnly={true}
                    />
                </Row>
            </Container>
        </React.Fragment>
        : (
            <span>Loading Appointment...</span>
        ))
}
export default AppointmentInfoView;